// Authored by Shaun Grady

angular.module('dropmenu', [])

.directive('dropmenu', [function (){
  var activeDropmenu,
      activeClass = 'dropmenu-show';

  // Just Global Things
  //////////////////////

  // Can accept null arg to unset active
  function setActiveDropmenu(dropmenu) {
    if (isActiveDropmenu(dropmenu))
      return;
    if (activeDropmenu)
      activeDropmenu.hide();
    activeDropmenu = dropmenu;
  }

  // For linking func to ensure it cleans up after itself when it hides
  function unsetDropmenuAsActive(dropmenu) {
    if (isActiveDropmenu(dropmenu))
      activeDropmenu = null;
  }

  function isActiveDropmenu(dropmenu) {
    return activeDropmenu && dropmenu && activeDropmenu.id === dropmenu.id;
  }

  // Global event handler for closing active on 'Esc' keypress
  $('body')
    .bind('keydown', function(e) {
      if (e.keyCode == 27)
        setActiveDropmenu(null);
    })
    .bind('click', function(e) {
      setActiveDropmenu(null);
    });


  // Directive Defition
  //////////////////////

  return {
    restrict:   'A',
    controller: 'dropmenuCtrl',
    scope:      true,
    link: function (scope, elem, attrs) {
      var dropmenu = scope.dropmenu,
          areBindingsDisabled,
          timer;

      // Augment dropmenu obj
      dropmenu.elem            = elem;
      dropmenu.show            = showDropmenu;
      dropmenu.hide            = hideDropmenu;
      dropmenu.disableBindings = disableBindings;
      dropmenu.enableBindings  = enableBindings;

      function showDropmenu(event) {
        // Prevent click event passing to body and closing menu
        if (event) event.stopPropagation();
        setActiveDropmenu(dropmenu);
        setStates(true);
      }
      function hideDropmenu() {
        unsetDropmenuAsActive(dropmenu);
        setStates(false);
      }

      function disableBindings() { areBindingsDisabled = true; }
      function enableBindings()  { areBindingsDisabled = false; }

      function setStates(state) {
        if (state === dropmenu.isShowing)
          return;
        dropmenu.elem.toggleClass(activeClass, state);
        dropmenu.showing = state;
        dropmenu.hiding  = !state;
        setTimeout(function(){ scope.$apply(); }, 0);
      }

      // Scope-controlled open behavior: opens when attr value is truthy
      if (attrs.dropmenu)
        scope.$watch(attrs.dropmenu, function(val) {
          var state    = !!val,
              oldState = dropmenu.showing;

          if (state === oldState)
            return;

          if (state) showDropmenu();
          else       hideDropmenu();
        });


      // Default hover behavior
      if (!attrs.dropmenu) {
        elem
          .bind('click', showDropmenu)
          .bind('mouseenter', function() {
            if (activeDropmenu)
              showDropmenu();
          })
          .bind('mousemove', function() {
            clearTimeout(timer);
            timer = setTimeout(showDropmenu, 25);
          })
          .bind('mouseleave', function() {
            clearTimeout(timer);
            timer = setTimeout(hideDropmenu, 100);
          });
      }


      elem.bind('keydown', function(event) {
        // Tab, Enter, PgUp, PgDn, Up, Down
        if (dropmenu.hiding || !/^(9|13|33|34|38|40)$/.test(event.which))
          return;
        event.preventDefault();

        if (areBindingsDisabled)
          return;

        if (event.which == 9)
          event.which = event.shiftKey ? 38 : 40;

        switch (event.which) {
          case 40: dropmenu.nextItem(); break;
          case 38: dropmenu.previousItem(); break;
          case 13: dropmenu.selectItem(); break;
        }
      });

    }
  };
}])




// Dropmenu Base Controller
// Instantiates before directive linking function is called.
// Sets 'dropmenu' object on scope which is augmented by
// the directive linking function with show/hide methods.
// Directive keeps track of the global state, only allowing
// one menu to be open at a time.

.controller('dropmenuCtrl', ['$scope', function($scope) {
  var self = this, activeItem, timer;
 
  // Contains all dropmenuItem directives, keyed by ngRepeat $index
  // for ease of selecting next and previous items.
  self.items = {};

  $scope.dropmenu = {
    id: $scope.$id,
    showing: false,
    hiding:  true,
    // Methods added by dropmenu directive linking func
    show: angular.noop,
    hide: angular.noop,
    // Other
    disableBindings: angular.noop,
    enableBindings:  angular.noop,
    // Methods called by dropmenu directive
    nextItem:     activateNextItem,
    previousItem: activatePreviousItem,
    selectItem:   selectItem
  };

  $scope.$watch('dropmenu.hiding', function(hiding) {
    if (hiding)
      resetActiveItem();
  });


  // dropmenuItem directive methods
  /////////////////////////////////

  self.registerItem = function(dropmenuItem, index) {
    self.items[index] = dropmenuItem;
    resetActiveItem();
  };

  self.unregisterItem = function(dropmenuItem) {
    delete self.items[dropmenuItem.index];
    if (self.items[0])
      self.items[0].activate();
    resetActiveItem();
  };

  self.updateItem = function(dropmenuItem, index, oldIndex) {
    self.items[index] = dropmenuItem;
    if (self.items[oldIndex].id === dropmenuItem.id)
      delete self.items[oldIndex];
    if (self.items[0])
      self.items[0].activate();
    resetActiveItem();
  };

  self.setActiveItem = function(dropmenuItem) {
    if (isActiveItem(dropmenuItem))
      return;
    if (activeItem)
      activeItem.deactivate();
    activeItem = dropmenuItem;
  };

  self.hideMenu = function() { $scope.dropmenu.hide(); };
  self.showMenu = function() { $scope.dropmenu.show(); };


  // Keyboard Support for dropmenuItems
  //////////////////////////////////////

  function activateNextItem() {
    var activeItem = getActiveItem(),
        nextItem;

    if (!activeItem)
      return;

    nextItem = self.items[getActiveItem().index + 1];
    if (nextItem)
      nextItem.activate();
    else
      self.items[0].activate();
  }

  function activatePreviousItem() {
    var activeItem = getActiveItem(),
        previousItem;

    if (!activeItem)
      return;
    
    previousItem = self.items[getActiveItem().index - 1];
    if (previousItem)
      previousItem.activate();
    else
      self.items[_.last(_.keys(self.items))].activate();
  }

  function selectItem() {
    if (activeItem)
      activeItem.select();
  }


  // Helper functions
  ////////////////////

  function getActiveItem() {
    var activeItem = _.findWhere(self.items, { active:true });
    if (!activeItem)
      activeItem = resetActiveItem();
    return activeItem;
  }

  function isActiveItem(dropmenuItem) {
    return activeItem && dropmenuItem && activeItem.id === dropmenuItem.id;
  }

  function resetActiveItem() {
    if (self.items[0])
      return self.items[0].activate();
  }

}])




.directive('dropmenuItem', [function () {

  return  {
    restrict: 'A',
    require: '^dropmenu',
    link: function(scope, elem, attrs, ctrl) {

      var dropmenuItem = {
        id:         scope.$id,
        index:      scope.$index,
        active:     false,
        activate:   activateItem,
        deactivate: deactivateItem,
        select:     selectItem,
      };


      function activateItem() {
        ctrl.setActiveItem(dropmenuItem);
        setStates(true);
        return dropmenuItem;
      }

      // Called exclusively by ctrl
      function deactivateItem() {
        setStates(false);
      }

      function setStates(state) {
        if (state === dropmenuItem.active)
          return;
        dropmenuItem.active = state;
        elem.toggleClass('active', state);
      }

      function selectItem(event) {
        if (event) {
          event.preventDefault();
          event.stopPropagation();
        }

        if (attrs.dropmenuItemSelect)
          scope.$apply(function() { scope.$eval(attrs.dropmenuItemSelect); });
        else if (attrs.href)
          window.location = scope.$eval(attrs.href);
        ctrl.hideMenu();
      }


      // Create
      ctrl.registerItem(dropmenuItem, scope.$index);

      // Update
      scope.$watch('$index', function(index, oldIndex) {
        if (index === oldIndex) return;
        dropmenuItem.index = index;
        ctrl.updateItem(dropmenuItem, index, oldIndex);
      });

      // Delete
      scope.$on('$destroy', function() {
        ctrl.unregisterItem(dropmenuItem);
        elem.unbind('mouseenter click');
      });


      // Mouse events
      ////////////////
      
      elem
        .bind('mouseenter', activateItem)
        .bind('click', selectItem);

    }
  };
}]);
