angular.module('xformaApp', [
  'dropmenu'
])

// .constant('STRIPE_KEY', 'pk_test_C5Wx6UGtJf4zWpcVKpfJA0gN') // TEST
.constant('STRIPE_KEY', 'pk_live_fEhxnjKW3QZb3orprXeGamhg') // LIVE

.controller('XformaCtrl', ['$scope', function($scope) {
  'use strict';

  if (window.location.search && window.location.search.substr(1,10) == 'order_uuid') {
    var uuid = window.location.search.substr(12, 36);
    Cookies.set('existingOrderUuid', uuid);
    window.location.replace(window.location.href.replace(/\?.*/, ''));
  }
}])

.controller('MkiiDetailsCtrl', ['$scope', function($scope) {
  'use strict';

  Shadowbox.init({
    continuous:     true,
    counterLimit:   50,
    counterType:    'skip',
    overlayOpacity: 0.9,
    initialHeight:  900,
    initialWidth:   1355,
    onOpen:         function() {
      document.getElementById('sb-nav-close').className    = 'arr x';
      document.getElementById('sb-nav-next').className     = 'arr';
      document.getElementById('sb-nav-previous').className = 'arr left';
    }
  });

}])

.controller('MkiiOrderCtrl', ['$scope', '$http', '$filter', 'mkiiPricing', 'mkiiIncludedColors', 'mkiiAdditionalColors', 'STRIPE_KEY',
    function($scope, $http, $filter, mkiiPricing, mkiiIncludedColors, mkiiAdditionalColors, STRIPE_KEY) {
  'use strict';

  $scope.nonPrimary = function (color) {
    return (color.color_name !== $scope.selectedColor.color_name);
  };


  // Both pages of order process use this controller.
  // ngForm on finalize page saves data to $scope.formData property.

  // Init
  $scope.formData    = {};

  $scope.chassis     = mkiiPricing.chassis;
  $scope.addOns      = {};
  $scope.addOnsArray = mkiiPricing.add_ons;

  $scope.colors           = mkiiIncludedColors;
  $scope.colorsAdditional = mkiiAdditionalColors;
  $scope.selectedColor    = null; // Primary, free color that's included

  $scope.existingOrderUuid = Cookies.get('existingOrderUuid');

  angular.forEach(mkiiPricing.add_ons, function(addOn) {
    $scope.addOns[addOn.sku] = addOn;
  });

  angular.forEach(mkiiIncludedColors, function(addOn) {
    $scope.addOns[addOn.sku] = addOn;
    $scope.addOnsArray.push(addOn);
  });

  angular.forEach(mkiiAdditionalColors, function(addOn) {
    $scope.addOns[addOn.sku] = addOn;
    $scope.addOnsArray.push(addOn);
  });

  $scope.selectColor = function(color) {
    angular.forEach($scope.colors, function(color) {
      if (color === $scope.selectedColor)
        color.selected = false;
    });
    color.selected = true;
    $scope.selectedColor = color;
  };

  readCookie();

  // Funcs

  $scope.$watch('addOns', updateTotalPrice, true);

  $scope.toggleSelection = function toggleSelection(sku) {
    $scope.addOns[sku].selected = !$scope.addOns[sku].selected;
  };



  function readCookie() {
    var skus = Cookies.get('selectedAddOns');
    if (skus)
      angular.forEach(skus.split('.'), function(sku) {
        var addOn = $scope.addOns[sku];
        if (addOn)
          addOn.selected = true;
      });

    var selectedColorSku = Cookies.get('selectedColor') || 'LLKREDV01S';
    $scope.selectColor($scope.addOns[selectedColorSku]);

    $scope.showAdditionalColors = Cookies.get('showAdditionalColors') == 'true';

    $scope.$watch('showAdditionalColors', function (val, oldVal) {
      if (val === oldVal)
        return;

      if (!val)
        angular.forEach($scope.colorsAdditional, function (color) {
          color.selected = false;
        });

      updateTotalPrice();
    });
  }

  function updateCookie() {


    var selectedAddOns = [];
    angular.forEach($scope.addOns, function(addOn) {
      if (addOn.selected)
        selectedAddOns.push(addOn.sku);
    });


    Cookies.set('selectedAddOns', selectedAddOns.join('.'), {
      expires: 86400 * 30 // 30 days
    });
    Cookies.set('selectedColor', $scope.selectedColor.sku, {
      expires: 86400 * 30 // 30 days
    });
    Cookies.set('showAdditionalColors', $scope.showAdditionalColors, {
      expires: 86400 * 30 // 30 days
    });
  }


  // P2-specific
  //////////////

  if ($scope.existingOrderUuid && $scope.formData) {
    $http.post('/pay/customer.php', { uuid: $scope.existingOrderUuid })
      .success(function(data) {
        if (data) {
          data.existing_order_uuid = $scope.existingOrderUuid;
          $scope.formData  = data;
          $scope.readonly  = true;
          $scope.noChassis = true;
          $scope.agree     = true;
          angular.forEach($scope.colors, function(color) {
            color.selected = false;
          });
        }
        else {
          $scope.existingOrderUuid = false;
          updateTotalPrice();
        }
      });
  }


  var patronSkus = ['RAD4XDRV01S', 'FRHD50V01S'];

  $scope.isPatron = false;

  $scope.taxRate  = 0;
  $scope.tax      = 0;
  $scope.discount = 0;


  function updateTotalPrice() {
    var subtotal = 0,
        tax      = 0,
        discount = 0,
        total    = 0;

    // Subtotal
    if (!$scope.existingOrderUuid)
      subtotal = mkiiPricing.chassis.price;
    else
      subtotal = 0;

    // Addons
    angular.forEach($scope.addOns, function(addOn) {
      if (addOn.selected)
        subtotal += addOn.price;
    });

    // Discount
    if ($scope.isPatron && !$scope.existingOrderUuid)
      angular.forEach(patronSkus, function(sku) { discount += $scope.addOns[sku].price; });

    // Tax
    if ($scope.taxRate)
      tax = Math.round((subtotal - discount) * $scope.taxRate * 100) / 100;

    $scope.tax        = tax      ? tax.toFixed(2)      : 0;
    $scope.discount   = discount ? discount.toFixed(2) : 0;
    $scope.totalPrice = (subtotal - discount + tax).toFixed(2);
    updateCookie();
  }

  $scope.$watch('formData.email',   updatePatronStatus);
  $scope.$watch('formData.country', updateShippingAndTax);
  $scope.$watch('formData.state',   updateShippingAndTax);

  function updateShippingAndTax(value) {
    if (!value)
      return;

    $http.post('/pay/shipping_and_tax.php', {
        country: $scope.formData.country,
        state:   $scope.formData.state
      })
      .success(function(data) {
        // Process shipping
        if (!data.shipping)
          $scope.shipping = '\u2014';
        else
          $scope.shipping = '$' + data.shipping + ' USD';
        $scope.taxRate = data.tax_rate;

        updateTotalPrice();
      });
  }


  function updatePatronStatus(email) {
    if (!email || $scope.existingOrderUuid)
      return;

    $http.post('/pay/patron_status.php', { email: email })
      .success(function(data) {
        $scope.isPatron = data.is_patron;

        if (data.is_patron)
          angular.forEach(patronSkus, function(sku) {
            $scope.addOns[sku].selected = true;
          });

        updateTotalPrice();
      });
  }


  // STRIPE PAY
  /////////////

  function getDataForSubmit() {
    var data = angular.copy($scope.formData);
    // Add chassis and add-ons to data
    if (!$scope.existingOrderUuid)
      data[$scope.chassis.sku] = 'yes';
    angular.forEach($scope.addOns, function(addOn) {
      if (addOn.selected)
        data[addOn.sku] = 'yes';
    });
    return data;
  }

  $scope.pay50 = function() {
    $scope.formData.deposit_50 = 'true';
    $scope.pay();
  };

  $scope.pay = function() {
    var formData = getDataForSubmit();

    $http.post('/pay/totals.php', formData)
      .success(function(data) {
        if (!data || !data.total)
          alert('Error calculating total, please try again.');
        else
          openStripe(data.total.toFixed(2), formData);
      });
  };

  function openStripe(price, formData) {
    StripeCheckout.open({
      key:         STRIPE_KEY,
      name:        'XForma',
      description: 'MBX MKII ($'+ price +' USD)',
      panelLabel:  'Pay $'+ price +' USD',
      image:       '/assets/images/hsi_logo.jpg',
      email:       $scope.formData.email,
      token:       function(response) { stripeCallback(response, formData); }
    });
  }


  function stripeCallback(response, formData) {
    $http.post('/pay/charge_v2.php', {
        token: response.id,
        form_data: formData
      })
      .success(function(data) {
        if (data.error)
          alert(data.error);
        else {
          $scope.finito = true;
          Cookies.expire('selectedAddOns');
        }
      });
  }


}]);
